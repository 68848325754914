import axios from "../../http/http";
import { Module, ActionTree } from "vuex";

const actions: ActionTree<any, any> = {
  fetchWeeklyReport({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true);
      try {
        const response = await axios.get("/api/v1/this_week/", {
          params: data || {},
        });
        const json = response.data;
        commit("saveThisWeek", json);
        resolve(response.data);
        commit("SET_LOADING", false);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          console.error(e);
          reject(err.message);
        }
      }
    });
  },
  fetchDayTimeline({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true);
      try {
        const response = await axios.get("/api/v1/day_status_timeline/", {
          params: data || {},
        });
        const json = response.data;
        commit("saveDayTimeline", json);
        resolve(response.data);
        commit("SET_LOADING", false);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          console.error(e);
          reject(err.message);
        }
      }
    });
  },
};
const reportsStore: Module<any, any> = {
  state: () => ({
    thisWeek: {},
    dayTimeline: [],
  }),
  mutations: {
    saveThisWeek(state, data) {
        state.thisWeek = data || {};
    },
    saveDayTimeline(state, data) {
        state.dayTimeline = data || [];
    }
  },
  actions,
  getters: {
    thisWeek(state) {
        return state.thisWeek;
    },
    dayTimeline(state) {
        return state.dayTimeline;
    }
  },
};

export default reportsStore;
