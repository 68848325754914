import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import '@/styles/index.css'
import '@/styles/main.scss'

const app = createApp(App)

// Vue Good Table
import VueGoodTablePlugin from 'vue-good-table-next';

// import the styles
import 'vue-good-table-next/dist/vue-good-table-next.css'

app.use(VueGoodTablePlugin);

// Global Filters
import createFilters from "./filters/filters";
createFilters(app);

// Directives
import directives from "./directives/index";
directives(app);

// ToolTip
import VueTippy from 'vue-tippy'
import "tippy.js/dist/tippy.css";
app.use(
    VueTippy,
    // optional
    {
        directive: 'tippy', // => v-tippy
        component: 'tippy', // => <tippy/>
    }
)

// v-calendar
import 'v-calendar/dist/style.css';
import VCalendar from 'v-calendar';
app.use(VCalendar);

// ToastNotification
import Toast, { PluginOptions, POSITION } from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
const options: PluginOptions = {
    position: POSITION.TOP_RIGHT,
    timeout: 3000,
};
app.use(Toast, options);

// timeline
import { plugin } from 'vue3-cute-component'
import 'vue3-cute-component/dist/style.css'
app.use(plugin)

app.use(store).use(router).mount('#app')
