import moment from "moment";
import {baseUrlNoEndSlash} from "@/http/global_urls";
import {default as toast} from "@/utils/toastUtils";

function createFilters(app: any) {
    app.config.globalProperties.$toast = toast;
    app.config.globalProperties.$filters = {
        getColorsClassesByName(name: any) {
            const colors: Record<string, any> = {
                'new': 'bg-[#0F6FC6] text-[#0F6FC6] bg-opacity-10',
                'inprogress': 'bg-[#F49100] text-[#F49100] bg-opacity-10',
                'clientapproved': 'bg-[#BFA701] text-[#BFA701] bg-opacity-10',
                'qa': 'bg-[#B074DD] text-[#B074DD] bg-opacity-10',
                'closed': 'bg-[#00B050] text-[#00B050] bg-opacity-10',
                'bug': 'bg-[#E21E1C] text-[#E21E1C] bg-opacity-10',
                'featurerequest': 'bg-[#6B9B11] text-[#6B9B11] bg-opacity-10',
                'changerequest': 'bg-[#00A7EF] text-[#00A7EF] bg-opacity-10',
            }
            const convName: string = name.toString()
                .replace(" ", "").replace("-", "").toLowerCase();
            return colors[convName] || colors['new'];
        },
        getOutlineColorsClassesByStatus(name: any) {
            const colors: Record<string, any> = {
                'new': 'bg-[#0F6FC6] text-[#0F6FC6] bg-opacity-10 border-[#0F6FC6]',
                'inprogress': 'bg-[#F49100] text-[#F49100] bg-opacity-10 border-[#F49100]',
                'clientapproved': 'bg-[#BFA701] text-[#BFA701] bg-opacity-10 border-[#BFA701]',
                'qa': 'bg-[#B074DD] text-[#B074DD] bg-opacity-10 border-[#B074DD]',
                'closed': 'bg-[#00B050] text-[#00B050] bg-opacity-10 border-[#00B050]',
                'bug': 'bg-[#E21E1C] text-[#E21E1C] bg-opacity-10 border-[#E21E1C]',
                'featurerequest': 'bg-[#6B9B11] text-[#6B9B11] bg-opacity-10 border-[#6B9B11]',
                'changerequest': 'bg-[#00A7EF] text-[#00A7EF] bg-opacity-10 border-[#00A7EF]',
            }
            const convName: string = name.toString()
                .replace(" ", "").replace("-", "").toLowerCase();
            return colors[convName] || colors['new'];
        },
        getBackgroundColor(name: any) {
            const colors: Record<string, any> = {
                'new': 'bg-[#0F6FC6]',
                'inprogress': 'bg-[#F49100]',
                'clientapproved': 'bg-[#BFA701]',
                'qa': 'bg-[#B074DD] text-[#B074DD]',
                'closed': 'bg-[#00B050]',
                'bug': 'bg-[#E21E1C]',
                'featurerequest': 'bg-[#6B9B11]',
                'changerequest': 'bg-[#00A7EF]',
            }
            const convName: string = name.toString()
                .replace(" ", "").replace("-", "").toLowerCase();
            return colors[convName] || colors['new'];
        },
        getTextColor(name: any) {
            const colors: Record<string, any> = {
                'new': 'text-[#0F6FC6]',
                'inprogress': 'text-[#F49100]',
                'clientapproved': 'text-[#BFA701]',
                'qa': 'text-[#B074DD]',
                'closed': 'text-[#00B050]',
                'bug': 'text-[#E21E1C]',
                'featurerequest': 'text-[#6B9B11]',
                'changerequest': 'text-[#00A7EF]',
            }
            const convName: string = name.toString()
                .replace(" ", "").replace("-", "").toLowerCase();
            return colors[convName] || colors['new'];
        },
        randomColorClass(index: any) {
            const colorClasses = [
                'bg-[#0F6FC6] text-[#0F6FC6] bg-opacity-10 border-[#0F6FC6]',
                'bg-[#F49100] text-[#F49100] bg-opacity-10 border-[#F49100]',
                'bg-[#BFA701] text-[#BFA701] bg-opacity-10 border-[#BFA701]',
                'bg-[#B074DD] text-[#B074DD] bg-opacity-10 border-[#B074DD]',
                'bg-[#00B050] text-[#00B050] bg-opacity-10 border-[#00B050]',
                'bg-[#E21E1C] text-[#E21E1C] bg-opacity-10 border-[#E21E1C]',
                'bg-[#6B9B11] text-[#6B9B11] bg-opacity-10 border-[#6B9B11]',
                'bg-[#00A7EF] text-[#00A7EF] bg-opacity-10 border-[#00A7EF]',
            ]
            return colorClasses[index > 5 ? index % 5 : index]
        },
        userStatusText(value: any) {
            const status = [
                'is looking at tasks',
                'is working on a task',
                'is having lunch',
                'is cooling down',
                'is on leave',
                'is at home',
                'DND mode',
            ]
            return status[value] || 'is looking at tasks';
        },
        addBaseUrl(value: any) {
            if (!value) {
                return ''
            }
            if (value.startsWith("http")) {
                return value;
            }
            return baseUrlNoEndSlash + value;
        },
        getFileName(value: any) {
            if (!value) {
                return ''
            }
            const arr = value.split("/");
            return arr[arr.length - 1];
        },
        formatTextRemoveUnderscore(value: any) {
            if (!value) {
                return "";
            }
            // replace all underscores with spaces and capitalize the first letter of each word
            return value.replace(/_/g, " ").replace(/\w\S*/g, (txt: any) => {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            });
        },
        durationConvert(value: any) {
            const num = value / 60;
            const hours = (num / 60);
            const rhours = Math.floor(hours);
            const minutes = (hours - rhours) * 60;
            const rminutes = Math.round(minutes);
            if (rhours > 0) {
                return rhours + " h " + rminutes + " m";
            } else {
                return rminutes + " m";
            }
        },
        niceDateFormat(value: any) {
            if (!value) {
                return "NA";
            }
            try {
                return moment(value).format("ddd h:mm a, MMM Do, YYYY");
            } catch (e) {
                return "NA";
            }
        },
        niceDateFormatNoYear(value: any) {
            if (!value) {
                return "NA";
            }
            try {
                return moment(value).format("ddd h:mm a, MMM Do");
            } catch (e) {
                return "NA";
            }
        },
        niceOnlyDateFormat(value: any) {
            if (!value) {
                return "NA";
            }
            try {
                return moment(value).format("Do MMMM YYYY");
            } catch (e) {
                return "NA";
            }
        },
        serverOnlyDateFormat(value: any) {
            if (!value) {
                return "NA";
            }
            try {
                return moment(value).format("YYYY-MM-DD");
            } catch (e) {
                return "NA";
            }
        },
        onlyDateFormat(value: any) {
            if (!value) {
                return "NA";
            }
            try {
                return moment(value).format("DD-MM-YYYY");
            } catch (e) {
                return "NA";
            }
        },
        onlyHourFormat(value: any) {
            if (!value) {
                return "NA";
            }
            try {
                return moment(value).format("h:mm a");
            } catch (e) {
                return "NA";
            }
        },
        roundNumber(value: any) {
            if (!value) {
                return "-";
            }
            return parseFloat(value).toFixed(2)
        },
        getUnits(value: any, label: any) {
            if (isNaN(value)) {
                return "";
            }
            if (label == "Energy") {
                return "kcal";
            }
            return "gms";
        }
    };
}

export default createFilters;
