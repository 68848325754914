import axios from "../../http/http";
import { Module, ActionTree } from "vuex";

const actions: ActionTree<any, any> = {

};
const userStatusStore: Module<any, any> = {
  state: () => ({
    userStatus: [],
    currentStatus: {},
  }),
  mutations: {
    saveUserStatus(state, data) {
        state.userStatus = data || [];
    },
    setCurrentStatus(state, data) {
      const index = state.userStatus.findIndex((item: any) => item.user_id === data.user_id);
      if(state.userStatus[index]) {
        state.userStatus[index].status = data.status;
        if(data.task_id) {
          state.userStatus[index].task_id = data.task_id;
        } else {
          state.userStatus[index].task_id = null;
        }
        state.currentStatus = data || {};
      } else {
        state.currentStatus = data || {};
      }
    },
    updateUserStatus(state, data) {
      const index = state.userStatus.findIndex((item: any) => item.user_id === data.user_id);
      state.userStatus[index].status = data.status;
      if(data.task_id) {
        state.userStatus[index].task_id = data.task_id;
      } else {
        state.userStatus[index].task_id = null;
      }
    }
  },
  actions,
  getters: {
    userStatus(state) {
        return state.userStatus;
    },
    currentStatus(state) {
      return state.currentStatus;
    },
  },
};

export default userStatusStore;
