<template>
  <img src="@/assets/logo.png" alt="" />
</template>

<script>
export default {
  name: "LogoIcon",
};
</script>

<style scoped></style>
