import axios from "../../http/http";
import {ActionTree, Module} from "vuex";

const actions: ActionTree<any, any> = {
    fetchProjects({commit, dispatch}, data: any) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            commit("SET_LOADING", true);
            try {
                const response = await axios.get("/api/v1/projects/", {
                    params: data || {},
                });
                const json = response.data;
                commit("saveProjects", json.assigned);
                resolve(response.data);
                commit("SET_LOADING", false);
            } catch (err: any) {
                commit("SET_LOADING", false);
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    console.error(e);
                    reject(err.message);
                }
            }
        });
    },
    fetchProjectBirdView({commit, dispatch}, data: any) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            commit("SET_LOADING", true);
            try {
                const response = await axios.get("/api/v1/project_bird_view/", {
                    params: data || {},
                });
                const json = response.data;
                commit("saveProjectBirdView", json);
                resolve(response.data);
                commit("SET_LOADING", false);
            } catch (err: any) {
                commit("SET_LOADING", false);
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    console.error(e);
                    reject(err.message);
                }
            }
        });
    },
    fetchProjectModules({commit, dispatch}, data: any) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            commit("SET_LOADING", true);
            try {
                const response = await axios.get("/api/v1/project_modules/", {
                    params: data || {},
                });
                const json = response.data;
                commit("saveProjectModules", json);
                resolve(response.data);
                commit("SET_LOADING", false);
            } catch (err: any) {
                commit("SET_LOADING", false);
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    console.error(e);
                    reject(err.message);
                }
            }
        });
    },
    fetchProjectModuleScreen({commit, dispatch}, data: any) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            commit("clearProjectModuleScreens");
            commit("SET_LOADING", true);
            try {
                const response = await axios.get("/api/v1/project_module_screens/", {
                    params: data || {},
                });
                const json = response.data;
                commit("saveProjectModuleScreens", json);
                resolve(response.data);
                commit("SET_LOADING", false);
            } catch (err: any) {
                commit("SET_LOADING", false);
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    console.error(e);
                    reject(err.message);
                }
            }
        });
    },
    fetchProjectModuleScreenAppend({commit, dispatch}, data: any) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {

            commit("SET_LOADING", true);
            try {
                const response = await axios.get("/api/v1/project_module_screens/", {
                    params: data || {},
                });
                const json = response.data;
                commit("saveProjectModuleScreensAppend", json);
                resolve(response.data);
                commit("SET_LOADING", false);
            } catch (err: any) {
                commit("SET_LOADING", false);
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    console.error(e);
                    reject(err.message);
                }
            }
        });
    },

    createNewProjectModule({ commit, dispatch }, data: any) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            const formData = new FormData();

            Object.keys(data).forEach((key: string) => {
                formData.append(key, data[key]);
            });
            commit("SET_LOADING", true);
            try {
                const response = await axios.post("/api/v1/fn_project_module/", formData);
                if(response.data.code == 2) {
                    reject(response.data.message);
                } else {
                    resolve(response.data);
                }
                commit("SET_LOADING", false);
            } catch (err: any) {
                commit("SET_LOADING", false);
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    console.error(e);
                    reject(err.message);
                }
            }
        });
    },

    createNewModuleScreens({ commit, dispatch }, data: any) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            // const formData = new FormData();
            //
            // Object.keys(data).forEach((key: string) => {
            //     formData.append(key, data[key]);
            // });
            commit("SET_LOADING", true);
            try {
                const response = await axios.post("/api/v1/project_modules/screens/", data);
                if(response.data.code == 2) {
                    reject(response.data.message);
                } else {
                    resolve(response.data);
                }
                commit("SET_LOADING", false);
            } catch (err: any) {
                commit("SET_LOADING", false);
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    console.error(e);
                    reject(err.message);
                }
            }
        });
    },
};
const projectsStore: Module<any, any> = {
    state: () => ({
        projects: [],
        projectBirdView: [],
        projectModules: [],
        projectModuleScreens: [],
        projectModuleScreensAppend: [],
    }),
    mutations: {
        saveProjects(state, data) {
            localStorage.setItem("projects", JSON.stringify(data));
            state.projects = data || [];
        },
        saveProjectBirdView(state, data) {
            state.projectBirdView = data.tasks_status || [];
        },
        saveProjectModules(state, data) {
            state.projectModules = data || [];
        },
        saveProjectModuleScreens(state, data) {
            state.projectModuleScreens = data.screens || [];
        },
        saveProjectModuleScreensAppend(state, data) {

            state.projectModuleScreensAppend = data.screens || [];
            // data.screens.forEach((screen: any) => {
            //     if (!state.projectModuleScreensAppend.find((s: any) => s.id === screen.id)) {
            //         state.projectModuleScreensAppend.push(screen);
            //     }
            // });
            // if(!state.projectModuleScreensAppend) {
            //     state.projectModuleScreensAppend = [];
            // }
        },
        clearProjectModuleScreens(state) {
            state.projectModuleScreens = [];
        }
    },
    actions,
    getters: {
        projects(state) {
            return state.projects;
        },
        projectBirdView(state) {
            return state.projectBirdView;
        },
        projectModules(state) {
            return state.projectModules;
        },
        projectModuleScreens(state) {
            return state.projectModuleScreens;
        },
        projectModuleScreensAppend(state) {
            return state.projectModuleScreensAppend;
        }
    },
};

export default projectsStore;
