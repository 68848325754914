<template>
  <!-- Add NavBar -->
  <div class="max-h-screen relative w-full">
    <div v-if="currentStatus.status == 2" class="min-h-screen w-full font-sans flex flex-col space-y-6 items-center justify-center">
      <LogoIcon class="h-24" />
      <h1 class="text-4xl font-medium">
        {{ $filters.formatTextRemoveUnderscore(activeUser.username) }} is having Lunch
      </h1>
      <img src="/img/lunch/lunch.gif" alt="Lunch"  class="h-60"/>
      <div class="">
        <button
            @click="updateCurrentStatus(0)"
            class="green-button">
          <span>Back</span>
        </button>
      </div>
    </div>
    <div v-else-if="currentStatus.status == 3" class="min-h-screen w-full font-sans flex flex-col space-y-6 items-center justify-center">
      <LogoIcon class="h-24" />
      <h1 class="text-4xl font-medium">
        {{ $filters.formatTextRemoveUnderscore(activeUser.username) }} is Cooling Down
      </h1>
      <img src="/img/cooling/relax.gif" alt="Relax"  class="h-60"/>
      <div class="">
        <button
            @click="updateCurrentStatus(0)"
            class="green-button">
          <span>Back</span>
        </button>
      </div>
    </div>
    <div v-else-if="currentStatus.status == 6" class="min-h-screen w-full font-sans flex flex-col space-y-6 items-center justify-center">
      <LogoIcon class="h-24" />
      <h1 class="text-4xl font-medium">
        {{ $filters.formatTextRemoveUnderscore(activeUser.username) }} is on Do Not Disturb mode
      </h1>
      <img src="/img/dnd/dnd.gif" alt="Relax"  class="h-60"/>
      <div class="">
        <button
            @click="updateCurrentStatus(0)"
            class="green-button">
          <span>Back</span>
        </button>
      </div>
    </div>
    <div
        v-else
        class="w-full font-sans relative">
      <l-loading></l-loading>
      <router-view/>
      <!-- build notifications bar tailwind css -->
      <div v-if="isNotificationAllowed == 'default'" class="fixed bottom-0 w-full left-0 right-0 z-50 backdrop-blur-2xl">
        <div
            class="container py-6 px-6 mx-auto xl:w-9/12 flex flex-row items-center justify-between border-gray-200 border-b-[1px]">
          <h2 class="text-xl">
            Want to get updates on tasks ? enable notifications ?
          </h2>
          <div class="">
            <button
                @click="requestNotificationPermission"
                class="green-button">

              <svg class="w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                   stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                <polyline points="20 6 9 17 4 12"></polyline>
              </svg>


              <span>Allow notifications</span>
            </button>
          </div>
        </div>
      </div>
      <div v-if="isNotificationAllowed == 'denied'" class="fixed bottom-0 w-full left-0 right-0 z-50 backdrop-blur-2xl">
        <div
            class="container py-6 px-6 mx-auto xl:w-9/12 flex flex-row items-center justify-between border-gray-200 border-b-[1px]">
          <h2 class="text-xl">
            Notifications Denied please allow notifications by clicking on the small lock icon (lock) in the address bar.
          </h2>
        </div>
      </div>
      <div
          :class="{
          'w-0': userStatus.length <= 0,
          'w-72': userStatus.length > 0,
        }"
          class="sticky bottom-0 right-10 float-right">
        <PeopleStatusComponent />
      </div>
    </div>

  </div>
</template>
<script>
import LLoading from "@/components/custom/loading/LLoading";
import {mapGetters} from "vuex";
import {POSITION} from "vue-toastification";
import PeopleStatusComponent from "@/components/people/PeopleStatusComponent.vue";
import LogoIcon from "@/components/common/LogoIcon.vue";

export default {
  components: {LogoIcon, PeopleStatusComponent, LLoading},
  computed: {
    ...mapGetters(["isLoading", "activeUser", "isLoggedIn", "currentStatus", "userStatus"]),
  },
  watch: {
    activeUser() {
      if(this.activeUser.role != 2) {
        this.connectToWebSocket();
        this.connectUserStatusSocket();
      }
    },
    currentStatus() {
      this.updateUserStatus();
    },
  },
  data() {
    return {
      isInRenewal: true,
      isSocketConnected: false,
      isUserStatusSocketConnected: false,
      userStatusSocket: null,
      isNotificationAllowed: "granted",
      isDocumentVisible: true,
    };
  },
  methods: {
    checkIfCollegePaymentInRenewal() {
      this.isInRenewal = true;
    },
    connectToWebSocket() {
      if (Object.keys(this.activeUser).length === 0) {
        return;
      }
      const token = localStorage.getItem("token");
      document.cookie = `x-auth-token=${token}; Path=/; Domain=.ldev.in; SameSite=Lax;`;
      setTimeout(() => {
        if (this.isSocketConnected) return;
        const socket = new WebSocket("wss://ao-api.ldev.in/ws/notifications/");
        socket.addEventListener('message', (event) => {
          const data = JSON.parse(event.data);
          if(Object.keys(data).includes("task_id")) {
            this.$store.commit('addNewNotification', data);
            this.sendNotification(data.body, data.task_id, data.notification_id);
          }
        });
        socket.addEventListener('open',  (event) => {
          this.isSocketConnected = true;
          console.log('WebSocket connection opened');
        });

        socket.addEventListener('close', (event) => {
          this.isSocketConnected = false;
          console.log('WebSocket connection closed');
        });

        socket.addEventListener('error', (event) => {
          this.isSocketConnected = false;
          console.log('WebSocket connection error');
        });
      }, 1000)
    },
    closeNotification(notificationId, taskId, click = false) {
      this.$store.dispatch("markNotificationAsRead", {
        notification_id: notificationId,
        is_read: true,
      }).then(() => {
        this.$store.commit('removeReadNotification', {
          notification_id: notificationId,
        });
      });
      if (click) {
        this.$router.push(`/task/${taskId}`);
      }
    },
    sendNotification(title, taskId, notificationId) {
      if(this.isDocumentVisible) {
        this.$toast.info(title, {
          timeout: 30000,
          position: POSITION.BOTTOM_RIGHT,
          onClick: () => {
            this.closeNotification(notificationId, taskId, true)
          },
          onClose: () => {
            this.closeNotification(notificationId, taskId)
          },
        });
        const audio = document.createElement('audio');
        audio.setAttribute('src', '/audio/notification.wav');
        audio.play();
      } else {
        if (!this.isNotificationAllowed) {
          return;
        }
        const notification = new Notification(title, {
          body: "Click to view task",
          icon: "https://op.ldev.in/img/logo.png",
        });
        notification.onclick = (event) => {
          event.preventDefault();
          this.closeNotification(notificationId, taskId)
          this.$router.push(`/task/${taskId}`);
        };
      }
    },
    checkNotificationPermission() {
      this.isNotificationAllowed = Notification.permission;
    },
    requestNotificationPermission() {
      if (Notification.permission === "granted") {
        this.isNotificationAllowed = true;
        return;
      }
      if (Notification.permission !== "denied") {
        Notification.requestPermission().then((permission) => {
          if (permission === "granted") {
            this.isNotificationAllowed = true;
          }
        });
      }
    },
    connectUserStatusSocket() {
      if (Object.keys(this.activeUser).length === 0) {
        return;
      }
      const token = localStorage.getItem("token");
      document.cookie = `x-auth-token=${token}; Path=/; Domain=.ldev.in; SameSite=Lax;`;
      setTimeout(() => {
        if (this.isUserStatusSocketConnected) return;
        this.userStatusSocket = new WebSocket("wss://ao-api.ldev.in/ws/jeremiel_channel/");
        this.userStatusSocket.addEventListener('message', (event) => {
          const data = JSON.parse(event.data);
          if(data.data) {
            this.$store.commit('saveUserStatus', data.data);
          } else {
            try {
              this.$store.commit('updateUserStatus', data);
            } catch (e) {
              console.log(e);
            }
          }
        });
        this.userStatusSocket.addEventListener('open', (event) => {
          this.isUserStatusSocketConnected = true;
          console.log('WebSocket connection opened');
        });

        this.userStatusSocket.addEventListener('close', (event) => {
          this.isUserStatusSocketConnected = false;
          console.log('WebSocket connection closed');
        });

        this.userStatusSocket.addEventListener('error', (event) => {
          this.isUserStatusSocketConnected = false;
          console.log('WebSocket connection error');
        });
      }, 1000)
    },
    updateUserStatus() {
      this.userStatusSocket.send(JSON.stringify(this.currentStatus));
    },
    updateCurrentStatus(value) {
      this.$store.commit("setCurrentStatus", {
        status: value,
        user_id: this.activeUser.id,
      })
    }
  },
  mounted() {
    this.checkNotificationPermission();
    this.checkIfCollegePaymentInRenewal();
    document.addEventListener('visibilitychange',(event) => {
      if (document.hidden) {
        this.isDocumentVisible = false;
      } else {
        this.isDocumentVisible = true;
        if(!this.isSocketConnected || !this.isUserStatusSocketConnected) {
          // if env is production
          if (process.env.NODE_ENV === 'production') {
            window.location.reload();
          }
        }
      }
    });

  },
  created() {
    this.$store.dispatch("checkLoginStatus");
    if (this.isLoggedIn) {
      this.$store.dispatch("fetchProjects");
      this.$store.dispatch('fetchStaticTasksData');
    }
  },
};
</script>
<style lang="scss">
</style>
