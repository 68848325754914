import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import store from "@/store/index";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Base',
    component: () => import(/* webpackChunkName: "base" */ '../layouts/NavBarLayout.vue'),
    children: [
      {
        path: '/',
        redirect: '/tasks',
        meta: { requiresAuth: true, role: "general", },
      },
      {
        path: '/notifications',
        name: 'notifications',
        meta: { requiresAuth: true, role: "general", },
        component: () => import(/* webpackChunkName: "task" */ '../views/notifications/NotificationsPage.vue'),
      },
      {
        path: '/tasks',
        name: 'tasks',
        meta: { requiresAuth: true, role: "general", },
        component: () => import(/* webpackChunkName: "task" */ '../views/tasks/TasksListPage.vue'),
      },
      {
        path: '/task/:taskId',
        name: 'task-details',
        props: true,
        meta: { requiresAuth: true, role: "general", },
        component: () => import(/* webpackChunkName: "task" */ '../views/tasks/TaskDetailsPage.vue')
      },
      {
        path: '/invoices',
        name: 'invoices',
        meta: { requiresAuth: true, role: "general", },
        component: () => import(/* webpackChunkName: "task" */ '../views/invoices/InvoicesListPage.vue'),
      },
      {
        path: '/invoice/generate',
        name: 'invoice-generate',
        meta: { requiresAuth: true, role: "general", },
        component: () => import(/* webpackChunkName: "task" */ '../views/invoices/GenerateInvoicePage.vue'),
      },
      {
        path: '/invoice/details/:invoiceId',
        name: 'invoice-details',
        props: true,
        meta: { requiresAuth: true, role: "general", },
        component: () => import(/* webpackChunkName: "task" */ '../views/invoices/GenerateInvoicePage.vue'),
      },

      {
        path: '/timeline/',
        name: 'timeline',
        props: true,
        meta: { requiresAuth: true, role: "general", },
        component: () => import(/* webpackChunkName: "task" */ '../views/reports/DailyTimelineView.vue'),
      },
      {
        path: '/this-week/',
        name: 'this-week',
        props: true,
        meta: { requiresAuth: true, role: "general", },
        component: () => import(/* webpackChunkName: "task" */ '../views/reports/WeeklyReport.vue'),
      },

      {
        path: '/project-bird-view/',
        name: 'project-bird-view',
        props: true,
        meta: { requiresAuth: true, role: "general", },
        component: () => import(/* webpackChunkName: "task" */ '../views/projects/ProjectBirdView.vue'),
      },
      {
        path: '/goal-details/:goalId',
        name: 'goal-details',
        props: true,
        meta: { requiresAuth: true, role: "general", },
        component: () => import(/* webpackChunkName: "task" */ '../views/projects/goals/GoalDetailPage.vue'),
      },

    ]
  },
  {
    path: "/",
    name: "NoAuth",
    component: () => import(/* webpackChunkName: "base" */ '../layouts/NoNavBarLayout.vue'),
    children: [
      {
        path: '/login',
        name: 'login',
        meta: { isLoginPage: true },
        component: () => import(/* webpackChunkName: "about" */ '../views/auth/LoginPage.vue')
      }
    ]
  },
  {
    // path: "*",
    path: "/:catchAll(.*)",
    name: "NotFound",
    redirect: "/",
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  },
})
router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth && !store.state.isLoggedIn) next({ name: "login" });
  else if (to.meta.isLoginPage && store.state.isLoggedIn)
    next({ name: "Home" });
  else {
    const metaRole = to.meta.role;
    if(!metaRole) {
      next();
    } else {
      next();
    }
  }
});

export default router
