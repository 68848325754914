import { createStore } from "vuex";
import authStore from "../store/auth/authStore";
import router from "@/router";
import projectsStore from "@/store/projects/projectsStore";
import tasksStore from "@/store/tasks/tasksStore";
import invoicesStore from "@/store/invoices/invoicesStore";
import notificationsStore from "@/store/tasks/notificationsStore";
import userStatusStore from "@/store/user/userStatusStore";
import reportsStore from "@/store/tasks/reportsStore";
import goalsStore from "@/store/projects/goalsStore";

export default createStore({
  state: {
    isLoggedIn: false,
    isLoading: false,
    activeUser: {},
  },
  mutations: {
    SET_LOGGED_IN(state, value) {
      state.isLoggedIn = value;
    },
    SET_LOADING(state, value) {
      state.isLoading = value;
    },
    SET_USER_DATA(state) {
      try {
        const user = localStorage.getItem("user");
        state.activeUser = JSON.parse(user || "{}");
      } catch (e) {
        state.activeUser = {};
      }
    },
  },
  getters: {
    isLoggedIn(state) {
      return state.isLoggedIn;
    },
    isLoading(state) {
      return state.isLoading;
    },
    activeUser(state) {
      return state.activeUser;
    },
  },
  actions: {
    checkLoginStatus({ commit, dispatch }) {
      const token = localStorage.getItem("token");
      if (token) {
        commit("SET_LOGGED_IN", true);
        dispatch("fetchProjects");
        dispatch('fetchStaticTasksData');
        commit("SET_USER_DATA");
      } else {
        commit("SET_LOGGED_IN", false);
        commit("SET_USER_DATA");
      }
    },
    logout({ commit }, data) {
      localStorage.clear();
      commit("SET_LOGGED_IN", false);
      commit("SET_USER_DATA");
      if(!data) {
        router.push("/");
        window.location.reload();
      }
    },
  },
  modules: {
    authStore,
    projectsStore,
    tasksStore,
    invoicesStore,
    notificationsStore,
    userStatusStore,
    reportsStore,
    goalsStore,
  },
});
