import axios from "../../http/http";
import { Module, ActionTree } from "vuex";

const actions: ActionTree<any, any> = {
  fetchInvoicesList({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true);
      try {
        const response = await axios.get("/api/v1/invoices/", {
          params: data || {},
        });
        const json = response.data;
        commit("saveInvoices", json);
        resolve(response.data);
        commit("SET_LOADING", false);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          console.error(e);
          reject(err.message);
        }
      }
    });
  },
  fetchInvoiceDetails({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true);
      try {
        const response = await axios.get("/api/v1/invoice/", {
          params: data || {},
        });
        commit("setInvoiceData", response.data);
        resolve(response.data);
        commit("SET_LOADING", false);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          console.error(e);
          reject(err.message);
        }
      }
    });
  },
  createNewInvoice({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await axios.post("/api/v1/invoice/", formData);
        resolve(response.data);
        commit("setInvoiceData", response.data);
        commit("SET_LOADING", false);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          console.error(e);
          reject(err.message);
        }
      }
    });
  },
  updateInvoice({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await axios.patch("/api/v1/invoice/", formData);
        resolve(response.data);
        commit("setInvoiceData", response.data);
        commit("SET_LOADING", false);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          console.error(e);
          reject(err.message);
        }
      }
    });
  },
};
const invoicesStore: Module<any, any> = {
  state: () => ({
    invoices: [],
    invoice: null,
    invoiceData: null,
  }),
  mutations: {
    setInvoiceData(state, data) {
        state.invoiceData = data || null;
    },
    saveInvoice(state, data) {
        localStorage.setItem("invoice-" + data.invoice.invoice_id, JSON.stringify(data));
        state.invoice = data || null;
    },
    saveInvoices(state, data) {
        localStorage.setItem("invoices", JSON.stringify(data));
        state.invoices = data || [];
    },
  },
  actions,
  getters: {
    invoiceData(state) {
        return state.invoiceData;
    },
    invoice(state) {
        return state.invoice;
    },
    invoices(state) {
      return state.invoices;
    },
  },
};

export default invoicesStore;
