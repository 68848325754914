<template>
  <div
      :class="{
          'w-0': userStatus.length <= 0,
          'w-72': userStatus.length > 0,
        }"
      class="my-4 transition transition-all">
    <div
        v-if="userStatus.length > 0"
        class="flex flex-col bg-white border border-[1px] shadow-xl rounded-md">
      <div @click="showContent = !showContent"
           class="py-1.5 cursor-pointer text-center px-4 bg-[#f6f8fa]">
        <div class="flex flex-row space-x-2 items-center justify-center">
          <p class="font-bold text-lg">
            Users
          </p>
          <span
              class="h-3 w-3 rounded-full bg-green-500">
        </span>
        </div>
      </div>
      <div
          :class="{
        'flex flex-col': showContent,
        'hidden': !showContent,
          }"
          class="py-3 divide-y-[1.2px]">
        <div
            v-for="(status, index) in userStatus"
            :key="index"
            class="flex flex-row items-center justify-between space-x-3 px-4 py-2 w-full">
          <div class="flex flex-row items-center space-x-3">
            <img
                class="rounded-full h-8 w-8 object-cover"
                :src="$filters.addBaseUrl(status.profile_image)"
                alt=""
                v-if="status.profile_image"
            />
            <UserCircleIcon class="h-8 w-8" aria-hidden="true" v-else/>
            <div class="flex flex-col">
              <p class="text-black font-medium">
                {{ $filters.formatTextRemoveUnderscore(status.username) }}
              </p>
              <p class="text-content-gray text-sm">
                {{ $filters.userStatusText(status.status) }}
                <span v-if="status.task_id" class="text-blue-800 font-medium">
                  <router-link :to="'/task/' + status.task_id">#{{ status.task_id }}</router-link>
                </span>
              </p>
            </div>
          </div>
          <!-- Live Indicator -->
          <div
              class="h-3 w-3 rounded-full bg-gray-400"
              :class="{
              '!bg-green-500': status.status == 0 || status.status == 1,
            }">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {UserCircleIcon} from "@heroicons/vue/24/solid";
import {mapGetters} from "vuex";

export default {
  name: "PeopleStatusComponent",
  components: {
    UserCircleIcon,
  },
  data() {
    return {
      showContent: false,
    }
  },
  computed: {
    ...mapGetters(["userStatus"]),
  },

}
</script>

<style scoped>

</style>