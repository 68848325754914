import axios from "../../http/auth";
import { Module, ActionTree } from "vuex";
import jwt_decode from "jwt-decode";

const actions: ActionTree<any, any> = {
  performLogin({ commit, dispatch }, loginDetails: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(loginDetails).forEach((key: string) => {
        formData.append(key, loginDetails[key]);
      });

      commit("SET_LOADING", true);
      try {
        const response = await axios.post("/api/token/", formData);
        commit("SET_LOGGED_IN", true);
        commit("SET_LOADING", false);
        const json = response.data;

        const decoded: any = jwt_decode(json.access);

        commit("saveModules", decoded.modules);
        commit("saveUser", JSON.stringify(decoded.profile_details));
        commit("SET_USER_DATA");

        commit("saveToken", json.access);
        commit("saveRefreshToken", json.refresh);

        dispatch("fetchProjects");
        dispatch('fetchStaticTasksData');

        // dispatch("fetchProfileDetails");
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          console.error(e);
          reject(err.message);
        }
      }
    });
  },
  tokenRefresh({ commit, dispatch }) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      const refreshToken = localStorage.getItem("refresh");
      if (refreshToken) {
        formData.append("refresh", refreshToken);

        try {
          const response = await axios.post("/api/token/refresh/", formData);
          commit("SET_LOGGED_IN", true);
          const json = response.data;
          commit("saveToken", json.access);

          const decoded: any = jwt_decode(json.access);

          commit("saveModules", decoded.modules);
          commit("saveUser", JSON.stringify(decoded.profile_details));
          commit("SET_USER_DATA");


          resolve(response.data);
        } catch (err: any) {
          dispatch("logout");
          try {
            reject(err.response.data.message);
          } catch (e) {
            reject(err.message);
          }
        }
      } else {
        dispatch("logout");
      }
    });
  },
};
const authStore: Module<any, any> = {
  state: () => ({}),
  mutations: {
    saveModules(state, modules) {
      localStorage.setItem("modules", JSON.stringify(modules));
    },
    saveUser(state, user) {
      localStorage.setItem("user", user);
    },
    saveToken(state, token) {
      localStorage.setItem("token", token);
      this.commit("SET_LOGGED_IN", true);
    },
    saveRefreshToken(state, token) {
      localStorage.setItem("refresh", token);
    },
  },
  actions,
  getters: {},
};

export default authStore;
