import axios from "../../http/http";
import { Module, ActionTree } from "vuex";

const actions: ActionTree<any, any> = {
  fetchNotifications({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true);
      try {
        const response = await axios.get("/api/v1/notifications/", {
          params: data || {},
        });
        const json = response.data;
        commit("saveNotifications", json);
        resolve(response.data);
        commit("SET_LOADING", false);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          console.error(e);
          reject(err.message);
        }
      }
    });
  },
  markNotificationAsRead({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await axios.patch("/api/v1/notification/", formData);
        resolve(response.data);
        commit("SET_LOADING", false);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          console.error(e);
          reject(err.message);
        }
      }
    });
  },
};
const notificationsStore: Module<any, any> = {
  state: () => ({
    notifications: [],
    notificationUnreadCount: 0,
  }),
  mutations: {
    saveNotifications(state, data) {
        localStorage.setItem("notifications", JSON.stringify(data));
        state.notifications = data.notifications || [];
        state.notificationUnreadCount = data.unread_count || 0;
    },
    addNewNotification(state, data) {
        state.notifications.unshift(data);
        state.notificationUnreadCount += 1;
    },
    removeReadNotification(state, data) {
        state.notifications = state.notifications.filter((item: any) => item.notification_id !== data.notification_id);
        state.notificationUnreadCount -= 1;
    }
  },
  actions,
  getters: {
    notifications(state) {
        return state.notifications;
    },
    notificationUnreadCount(state) {
      return state.notificationUnreadCount;
    },
  },
};

export default notificationsStore;
