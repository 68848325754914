import axios from "../../http/http";
import { Module, ActionTree } from "vuex";

const actions: ActionTree<any, any> = {
  fetchStaticTasksData({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true);
      try {
        const response = await axios.get("/api/v1/task_stores/", {
          params: data || {},
        });
        const json = response.data;
        commit("saveTaskTypes", json.types);
        commit("saveTaskKinds", json.kinds);
        resolve(response.data);
        commit("SET_LOADING", false);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          console.error(e);
          reject(err.message);
        }
      }
    });
  },
  fetchTasksList({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true);
      try {
        const response = await axios.get("/api/v1/tasks/", {
          params: data || {},
        });
        const json = response.data;
        commit("saveTasks", json);
        resolve(response.data);
        commit("SET_LOADING", false);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          console.error(e);
          reject(err.message);
        }
      }
    });
  },
  fetchTaskDetails({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true);
      try {
        const response = await axios.get("/api/v1/task/", {
          params: data || {},
        });
        const json = response.data;
        commit("saveTask", json);
        resolve(response.data);
        commit("SET_LOADING", false);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          console.error(e);
          reject(err.message);
        }
      }
    });
  },
  updateTaskData({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await axios.patch("/api/v1/task/", formData);
        resolve(response.data);
        commit("SET_LOADING", false);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          console.error(e);
          reject(err.message);
        }
      }
    });
  },
  createNewTask({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await axios.post("/api/v1/task/", formData);
        if(response.data.code == 2) {
          reject(response.data.message);
        } else {
          resolve(response.data);
        }
        commit("SET_LOADING", false);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          console.error(e);
          reject(err.message);
        }
      }
    });
  },
};
const tasksStore: Module<any, any> = {
  state: () => ({
    tasks: [],
    statusSummary: {},
    typeSummary: {},
    taskTypes: [],
    taskKinds: [],
    task: null,
  }),
  mutations: {
    saveTask(state, data) {
        localStorage.setItem("task-" + data.task.task_id, JSON.stringify(data));
        state.task = data || null;
    },
    saveTasks(state, data) {
        localStorage.setItem("tasks", JSON.stringify(data));
        state.tasks = data.tasks || [];
        state.statusSummary = data.status_summary[0];
        state.typeSummary = data.type_summary[0];
    },
    saveTaskTypes(state, data) {
      localStorage.setItem("tasks-types", data);
      state.taskTypes = data || [];
    },
    saveTaskKinds(state, data) {
        localStorage.setItem("tasks-kinds", data);
        state.taskKinds = data || [];
    },
    getTaskTypes(state) {
      if(localStorage.getItem("tasks-types")) {
        state.taskTypes = JSON.parse(localStorage.getItem("tasks-types") || "");
      }
    },
    getTaskKinds(state) {
        if(localStorage.getItem("tasks-kinds")) {
            state.taskKinds = JSON.parse(localStorage.getItem("tasks-kinds") || "");
        }
    }
  },
  actions,
  getters: {
    task(state) {
        return state.task;
    },
    tasks(state) {
      return state.tasks;
    },
    statusSummary(state) {
        return state.statusSummary;
    },
    typeSummary(state) {
        return state.typeSummary;
    },
    taskTypes(state) {
        return state.taskTypes;
    },
    taskKinds(state) {
        return state.taskKinds;
    }
  },
};

export default tasksStore;
