<template>
  <div v-show="showIt" class="relative z-10">
    <!-- Linear Progress Bar -->
    <div class="h-1.5 bg-blue-900 absolute left-0 top-0 transition-all" ref="progressBar"></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "LLoading",
  data() {
    return {
      interval: null,
      showIt: false,
    };
  },
  components: {
  },
  computed: {
    ...mapGetters(["isLoading"]),
  },
  watch: {
    isLoading(val, oldVal) {
      if (val) {
        this.showIt = true;
        setTimeout(() => {
          this.startLoadingAnimation();
        }, 1000);
      } else {
        if(this.interval) {
          clearInterval(this.interval);
        }
        if(this.$refs.progressBar) {
          this.$refs.progressBar.style.right = `100%`;
        }
        setTimeout(() => {
          this.showIt = false;
        }, 1000);
      }
    },
  },
  methods: {
    startLoadingAnimation() {
      let progress = 20;
      this.$refs.progressBar.style.right = `${100 - progress}%`;
      this.interval = setInterval(() => {
        progress += 20;
        this.$refs.progressBar.style.right = `${100 - progress}%`;
        if (progress >= 80) {
          clearInterval(this.interval);
        }
      }, 500);
    },
  },
  beforeUnmount() {
    if(this.interval) {
      clearInterval(this.interval);
    }
  },
  mounted() {
    // this.startLoadingAnimation();
  }
};
</script>

<style lang="scss">
//.loading-blur :not(#loading-overlay):not(#loading-overlay *) {
//  filter: blur(5px);
//}
//.loading-parent {
//  background-color: rgba(0, 0, 0, 0.1);
//}
</style>
