import axios from "../../http/http";
import { Module, ActionTree } from "vuex";

const actions: ActionTree<any, any> = {
  fetchGoalsList({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true);
      try {
        const response = await axios.get("/api/v1/goals/", {
          params: data || {},
        });
        const json = response.data;
        commit("saveGoals", json);
        resolve(response.data);
        commit("SET_LOADING", false);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          console.error(e);
          reject(err.message);
        }
      }
    });
  },
  fetchGoalDetails({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true);
      try {
        const response = await axios.get("/api/v1/goal/", {
          params: data || {},
        });
        const json = response.data;
        commit("saveGoal", json);
        resolve(response.data);
        commit("SET_LOADING", false);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          console.error(e);
          reject(err.message);
        }
      }
    });
  },
  createNewGoal({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await axios.post("/api/v1/goal/", formData);
        if(response.data.code == 2) {
          reject(response.data.message);
        } else {
          resolve(response.data);
        }
        commit("SET_LOADING", false);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          console.error(e);
          reject(err.message);
        }
      }
    });
  },
};
const goalsStore: Module<any, any> = {
  state: () => ({
    goals: [],
    goal: {},
  }),
  mutations: {
    saveGoals(state: any, data: any) {
        state.goals = data;
    },
    saveGoal(state: any, data: any) {
        state.goal = data.data;
    }
  },
  actions,
  getters: {
    goals(state: any) {
        return state.goals;
    },
    goal(state: any) {
        return state.goal;
    }
  },
};

export default goalsStore;
